import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { FormControl, MenuItem, Select, Checkbox, FormControlLabel, Paper, Box,Button, Menu, Typography, InputLabel } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ChartComponent from "../components/charts/ChartComponent";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
    border: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  select: {
    border: 'none',
    margin: 0,
  },
  customTypography: {
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '3px',
    letterSpacing: '0.04em',
    textAlign: 'left',
    margin:'0px',
    padding:'4px'
  },

}));

const GraphCard = ({ selectedOption, handleChange, recencyFilters, handleRecencyFilterChange, selectedCandidate, filters, handleFilterChange, handleClick, anchorEl, menuOpen, handleClose, handleMenuItemClick, index, direct }) => {
  const classes = useStyles();
  const skillsKey = selectedCandidate?.resumeData?.scores[index]?.result[0].masterSkills.filter(skill => filters.includes(skill.criticality));
  const [profileIndex, setProfileIndex] = useState('');
  


  const compositeKey = `${selectedCandidate._id}-${skillsKey}`;
  useEffect(() => {
    console.log('selectedCandidate updated:', selectedCandidate);
    console.log("selectedoption", selectedCandidate?.resumeData?.scores)
}, [selectedCandidate]);

  return (
    <Card variant="outlined" style={{ width: "100%", borderLeft: '6px solid #EAF0F9', borderTopLeftRadius: '10px', 
    borderBottomLeftRadius: '10px', backgroundColor: "#F8FAFD", paddingBottom:"0px" }} >
        
        
        <CardContent style={{paddingBottom:"5px", 
         display: 'flex',
          justifyContent: 'center', // Center content horizontally
          alignItems: 'center'  // Center content vertically
}} ><Box 
        style={{ 
          backgroundColor: "#F8FAFD", 
          height: "100%", 
          width: '100%',         }}
      >
        <FormControl  variant="outlined" style={{ minWidth: '200px', border:"none", marginTop:"5px"}} className={classes.formControl}>
              <div>
  <Button 
            variant="contained" 
            onClick={() => handleChange("secondGrid")}
            sx={{ backgroundColor: selectedOption === 'secondGrid'? '#D0E1FA' : '#F8FAFD', '&:hover': { backgroundColor: selectedOption === 'secondGrid'? '#D0E1FA' : '#F8FAFD' }, marginRight: 1, color: selectedOption === 'secondGrid'? 'black' : '#545454' }}
          >
            <Typography class= {classes.customTypography}> Job Requirements Match </Typography>
          </Button>
          <Button 
            variant="contained" 
            onClick={() => handleChange("firstGrid")}
            sx={{ backgroundColor: selectedOption === 'firstGrid'? '#D0E1FA' : '#F8FAFD', '&:hover': { backgroundColor: selectedOption === 'firstGrid'? '#D0E1FA' : '#F8FAFD' }, marginRight: 1, color: selectedOption === 'firstGrid'? 'black' : '#545454'  }}
          >
            <Typography class= {classes.customTypography}>Candidate Skills</Typography>
          </Button>
  </div>
            </FormControl>
              {selectedOption === 'firstGrid' && (<>

                <Paper square elevation={1} sx={{ mt: 2, width: 'fit-content' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    Recency: &nbsp;
                    <FormControlLabel
                      control={<Checkbox sx={{
                        '&.Mui-checked': {
                          color: '#003366',
                        },
                      }} checked={recencyFilters.indexOf('0') > -1} onChange={handleRecencyFilterChange} value="0" />}
                      label="0"
                    />
                    <FormControlLabel
                      control={<Checkbox sx={{
                        '&.Mui-checked': {
                          color: '#006699',
                        },
                      }} checked={recencyFilters.indexOf('1') > -1} onChange={handleRecencyFilterChange} value="1" />}
                      label="1"
                    />
                    <FormControlLabel
                      control={<Checkbox sx={{
                        '&.Mui-checked': {
                          color: '#8EDBEC',
                        },
                      }} checked={recencyFilters.indexOf('2') > -1} onChange={handleRecencyFilterChange} value="2" />}
                      label="2"
                    />
                    <FormControlLabel
                      control={<Checkbox sx={{
                        '&.Mui-checked': {
                          color: '#BBBBBB',
                        },
                      }} checked={recencyFilters.indexOf('3') > -1} onChange={handleRecencyFilterChange} value="3" />}
                      label="3"
                    />
                    <FormControlLabel
                      control={<Checkbox sx={{
                        '&.Mui-checked': {
                          color: '#DDDDDD',
                        },
                      }} checked={recencyFilters.indexOf('4+') > -1} onChange={handleRecencyFilterChange} value="4+" />}
                      label="4+"
                    />
                  </Box>
                </Paper>

                <br />
                Candidate Profile

                <ChartComponent type={'job'} key={selectedCandidate?.resumeData?.extracted?.skills.filter(skill => {
                  // If recency is a number and it matches a filter, return true
                  if (recencyFilters.includes(skill.recency.toString())) {
                    return true;
                  }
                  // If recency is >= 4 and the "4+" filter is active, return true
                  if (skill.recency >= 4 && recencyFilters.includes('4+')) {
                    return true;
                  }
                  return false; // Otherwise, this skill isn't included by the active filters
                })} skillsData={selectedCandidate?.resumeData?.extracted?.skills.filter(skill => {
                  // If recency is a number and it matches a filter, return true
                  if (recencyFilters.includes(skill.recency.toString())) {
                    return true;
                  }
                  // If recency is >= 4 and the "4+" filter is active, return true
                  if (skill.recency >= 4 && recencyFilters.includes('4+')) {
                    return true;
                  }
                  return false; // Otherwise, this skill isn't included by the active filters
                })} />
              </>

              )}

              {selectedOption === 'secondGrid' && (
                <>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    
    

                  &nbsp; &nbsp;
                  <Paper square elevation={1} sx={{  mt: 2, width: 'fit-content' }}>
                    
                      Criticality: &nbsp;
                      <FormControlLabel
                        control={<Checkbox sx={{
                          '&.Mui-checked': {
                            color: '#003366',
                          },
                        }} checked={filters.indexOf('High') > -1} onChange={handleFilterChange} value="High" />}
                        label="High"
                      />
                      <FormControlLabel
                        control={<Checkbox sx={{
                          '&.Mui-checked': {
                            color: '#8EDBEC',
                          },
                        }} checked={filters.indexOf('Medium') > -1} onChange={handleFilterChange} value="Medium" />}
                        label="Medium"
                      />
                      <FormControlLabel
                        control={<Checkbox sx={{
                          '&.Mui-checked': {
                            color: '#DDDDDD',
                          },
                        }} checked={filters.indexOf('Low') > -1} onChange={handleFilterChange} value="Low" />}
                        label="Low"
                      />
                      
                  </Paper>
                    </Box>


                  <div>
                    
                    <span >
                      <br />
                      Map of Candidate to Job Profile&nbsp;
                    </span>
                    <span style={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                      color: 'blue'
                    }}>
                      {selectedCandidate?.resumeData?.scores[index]?.result[0].profileName}

                    </span>
                    &nbsp;
                    <span>with score</span>
                    <span style={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                      color: 'blue'
                    }}> {direct? selectedCandidate?.resumeData?.scores[index]?.score.Combined: selectedCandidate?.resumeData?.scores[index]?.score.Direct} </span>
                  </div>

                  <ChartComponent
                    key={compositeKey}
                    skillsData={selectedCandidate?.resumeData?.scores[index]?.result[0].masterSkills.filter(skill => filters.includes(skill.criticality))}
                    secondSkillsData={direct 
                      ? selectedCandidate?.resumeData?.scores[index]?.transferable_skills : selectedCandidate?.resumeData?.scores[index]?.direct_skills
                  }
                    type={'candidate'}
                  />
                </>
              )}
              </Box>
</CardContent>
</Card>
  );
};

export default GraphCard;
