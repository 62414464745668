import React, { useState, useRef, useEffect } from 'react';
import ReactSlider from 'react-slider';
import Typography from '@mui/material/Typography';
import './rangeSliderWithLabel.css';

const RangeSliderWithLabel = ({ textLabel, initialSliderValue = [0, 40], saveSliderValue, sliderMax = 39 }) => {
  const [sliderValue, setSliderValue] = useState(initialSliderValue);
  const [containerWidth, setContainerWidth] = useState('100%');
  const labelRef = useRef(null);
  const sliderRef = useRef(null);
  const min = 0;

  useEffect(() => {
    if (labelRef.current) {
      setContainerWidth(`${labelRef.current.offsetWidth}px`);
    }
  }, [labelRef.current?.offsetWidth]);

  const handleSliderChange = (newSliderValue) => {
    const clampedValue = [
      Math.min(Math.max(newSliderValue[0], min), sliderMax),
      newSliderValue[1] > sliderMax ? "MAX" : Math.min(newSliderValue[1], sliderMax)
    ];
    setSliderValue(clampedValue);
    saveSliderValue(clampedValue);
  };

  return (
    <div>
      <div className="slider-container">
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="thumb"
          trackClassName="track"
          ref={sliderRef}
          min={min}
          max={sliderMax}
          value={sliderValue}
          onChange={handleSliderChange}
          renderThumb={(props, state) => (
            <div
              {...props}
              style={{
                ...props.style,
                backgroundColor: state.valueNow === sliderValue[0] ? '#A768ff' : '#1976d2', // Differentiate thumbs
              }}
            >
              {state.valueNow}
            </div>
          )}
          pearling
          minDistance={1} // Minimum distance between thumbs
        />
        <Typography
          ref={labelRef}
          variant="body2"
          sx={{
            position: 'absolute',
            top: '-7px',
            left: '-24%',
            transform: 'translateX(-25%)',
            color: '#000'
          }}
        >
          {textLabel} &nbsp;&nbsp;&nbsp;
        </Typography>

      </div>
    </div>
  );
};

export default RangeSliderWithLabel;
