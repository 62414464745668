import React, { useState } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

function TriStateSwitch({ missingDateSwitch, onValueChange }) {
  const [state, setState] = useState(missingDateSwitch); // null represents "don't care"

  const handleChange = (event, newValue) => {
    setState(newValue);
    onValueChange(newValue);
  };

  return (
    <ToggleButtonGroup
      sx={{ position: "relative", height: "20px", top: "-10px"}}
      value={state}
      exclusive
      onChange={handleChange}
      aria-label="Tri-state switch"
    >
      <ToggleButton value={0} aria-label="Good">
        Good
      </ToggleButton>
      <ToggleButton value={null} aria-label="Any">
        Any
      </ToggleButton>
      <ToggleButton value={1} aria-label="Partial">
        Partial
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default TriStateSwitch;
