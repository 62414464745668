import React, { useState, useEffect } from 'react';
import { Slider, TextField, Container, Typography, RadioGroup, FormControlLabel, Radio, Switch, Button, Box, Grid, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from "react-redux";
import { basicThunk, updatebasicThunk } from '../services/settingsThunk';
import { dispatch } from 'd3';
import { TextFormatRounded } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
  headingLimits: {
    fontWeight: 'bold',
  },
  customFontLimits: {
    fontStyle: 'italic',
  },
  circle: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 'bold',
  },
  greenCircle: {
    backgroundColor: 'green',
  },
  yellowCircle: {
    backgroundColor: 'yellow',
  },
  redCircle: {
    backgroundColor: 'red',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '2',
  },
  inputBox: {
    width: 100,
  },
  title3: {
      color: "var(--text-colour-dark, #172B4D) !important",
      fontFamily: "Poppins !important",
      fontSize: "30px !important",
      fontStyle: "bold !important",
      fontWeight:"700 !important",
      lineHeight: "22px !important", 
      backgroundColor:"inherit",
    },
    heading: {
        color: "var(--text-colour-dark, #172B4D) !important",
        fontFamily: "Poppins !important",
        fontSize: "22px !important",
        fontWeight: "600 !important" ,
        lineHeight: "20px !important", 
      },
      customFont: {
        color: '#172B4D !important',
        fontFamily: 'Poppins !important',
        fontSize: '14px !important',
        fontStyle: 'normal !important',
        fontWeight: "400 !important",
        lineHeight: 'normal !important',
        letterSpacing: '-0.26px !important',
      },
  }));

function Basicsettings() {
  const [userType, setUserType] = useState(null);
  const [multipleProfiles, setMultipleProfiles] = useState(null);
  const [HOPS_THRESHOLD, setHOPS_THRESHOLD]=useState(null);
  const [greenLimit, setGreenLimit] = useState(2); // Default initial value for green limit
  const [yellowLimit, setYellowLimit] = useState(4); // Default initial value for yellow limit
  const [hasChanged, setHasChanged] = useState(false);
  const temp = useSelector(state => state.settings);
  const [settings,updateSettings] = useState(useSelector(state => state.settings.basic))
  //const settings = useSelector((state) => state.settings.basic);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleGreenLimitChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setGreenLimit(newValue);
    setHasChanged(true);
    if (newValue >= yellowLimit) {
      setYellowLimit(newValue + 1); // Ensure yellowLimit is greater than greenLimit
    }
  };

  const handleYellowLimitChange = (event) => {
    const newValue = parseFloat(event.target.value);
    if (newValue > greenLimit) {
      setYellowLimit(newValue);
      setHasChanged(true);
    }
  };

  
{/*
      const temp = useSelector(state => state.users);
    const [users,setusers] = useState(useSelector(state => state.users.users))

    console.log(users)
    useEffect(() => {
      console.log(temp.users)
      setusers(temp.users)
  }, [temp.users]);

    useEffect(() => {
      dispatch(userThunk());
  }, [dispatch]);
 */}

  useEffect( () => { 
    updateSettings(temp.basic);
  }, [temp.basic]);

  useEffect(() => {

    if (settings) {
      
      setUserType(settings?.inviteUsers);
      setMultipleProfiles(settings?.multipleprofiles); // Assuming your storage has 'True' or 'False' as strings
      setHOPS_THRESHOLD(settings?.HOPS_THRESHOLD);
      setGreenLimit(settings?.HOPS_BADGES?.green);
      setYellowLimit(settings?.HOPS_BADGES?.yellow);
    }
  }, [settings]);

  useEffect(() => {
    dispatch(basicThunk());
  },[dispatch]);

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
    setHasChanged(true);
  };

  const handleMultipleProfilesChange = () => {
    setMultipleProfiles((prev) => !prev);
    setHasChanged(true);
  };

  const handleHopsThresholdChange = (event) => {
    setHOPS_THRESHOLD(event.target.value);
    setHasChanged(true);
  };

  const handleSave = async () => {
   await dispatch(updatebasicThunk({update:{"inviteUsers":userType,"multipleprofiles":multipleProfiles, "HOPS_THRESHOLD": HOPS_THRESHOLD, "HOPS_BADGES": {'green': greenLimit, 'yellow': yellowLimit}}}))
    // After saving, you would want to set hasChanged back to false
    setHasChanged(false);
    window.location.reload();
  };

  return (
    <>
    <br/>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.title3} gutterBottom>
          Basics
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!hasChanged} // This will disable the button when hasChanged is false
        >
          Save
        </Button>
      </Box>
      <br/>
      <Container style={{ backgroundColor: "#FFFFFF" }}>
      <br/>
      <Typography className={classes.heading} gutterBottom>
          Inviting New Users
        </Typography>
        <Typography classes={classes.customFont} paragraph>
          The following user types are allowed to invite new users to blujin:
          </Typography>
        <RadioGroup value={userType} onChange={handleUserTypeChange}>
          <FormControlLabel value="administrators" control={<Radio />} label="Administrators" />
          <FormControlLabel value="all_users" control={<Radio />} label="All users" />
        </RadioGroup>
        
        <hr />
        <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.heading} gutterBottom>
          Multiple Profiles
        </Typography>
        <Switch checked={multipleProfiles} onChange={handleMultipleProfilesChange} />
        </Box>
        <Typography classes={classes.customFont} paragraph>
          Enable users to create multiple profiles for each job.
        </Typography>

        <hr />
        <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.heading} gutterBottom>
          Hops Threshold
        </Typography>
        <TextField type="number" sx={{ minWidth:"120px"}} inputProps={{min:12, max:60, step:3}} value={HOPS_THRESHOLD} onChange={handleHopsThresholdChange} label="Hops Threshold" variant="outlined" InputLabelProps={{shrink: true}}/>
        </Box>
        <Typography classes={classes.customFont} paragraph>
          Sets the number of months below which a job experience is considered to be a hop.
        </Typography>

        <hr />

        <Typography className={classes.heading} gutterBottom>
          Hops Badge Limits
        </Typography>
        <Typography classes={classes.customFont} paragraph>
          Number of hops at which the Hops badge is set to different colors.
        </Typography>
        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
         <Grid item xs={1.5}>
         </Grid>
            <Grid item xs={3}>
                <Box sx={{ marginBottom: 2 }}>
                <Typography>Green Limit (upto this is green):</Typography>
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                  <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "22px", height: "22px", borderRadius: '50%', backgroundColor: "#4caf50" }}>
                    <Typography style={{ position: 'relative', top: '-2px', left: '-4px', color: 'white', fontSize: '16px' }}>{greenLimit}</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      sx={{ width: 100 }}
                      value={greenLimit}
                      type="number"
                      onChange={handleGreenLimitChange}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                </Grid>
              </Box>
          </Grid>
          <Grid item xs={3}>
                <Box sx={{ marginBottom: 2 }}>
                <Typography>Yellow Limit (upto this is yellow):</Typography>
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                  <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "22px", height: "22px", borderRadius: '50%', backgroundColor: "#FFC000", textAlign: 'top' }}>
                    <Typography variant="caption" style={{ position: 'relative', top: '-2px', left: '-4px', color: 'white', fontSize: '16px' }}>
                    {yellowLimit}
                </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      sx={{  width: 100 }}
                      value={yellowLimit}
                      type="number"
                      onChange={handleYellowLimitChange}
                      inputProps={{ min: {greenLimit}, max: 9}}
                    />
                  </Grid>
                </Grid>
              </Box>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left'}}>
                <Box sx={{ marginBottom: -1}}>
                <Typography>Values above {yellowLimit}</Typography>
                <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: "22px", height: "22px", borderRadius: '50%', backgroundColor: "red", top: "-11px", textAlign: 'top' }}>
                    <Typography variant="caption" style={{ position: 'relative', top: '-2px', left: '-4px', color: 'white', fontSize: '16px' }}>
                    {yellowLimit + 1}
                </Typography>
                  </Grid>
                  <Grid item sx={{minHeight: "100px"}}>
                    <Typography>&nbsp;</Typography>
                  </Grid>
                </Grid>
              </Box>
          </Grid>
        </Grid>
      <br/>
      <br/>
      </Container>
    </>
  );
}

export default Basicsettings;
