import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Autocomplete, Box } from '@mui/material';
import axios from "axios";


const AddSkillDialog = ({ open, onSave, onClose , skillsData}) => {
  const API_BASE = process.env.REACT_APP_API;
  const [newSkill, setNewSkill] = useState({ skillName: '', onetSkillMastery: 1, criticality: 'Low', profession: 'General', requiredExperience: 0 });
  const [skillSuggestions, setSkillSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const API = `${API_BASE}/main/search-skill`;

  // Fetch skill suggestions from the API
  useEffect(() => {
    const fetchSkills = async () => {
        setSkillSuggestions([])
      if (inputValue) {
        try {
          const token = localStorage.getItem('token');
            // Set up the query parameters
          const params = {};
          params.searchtext = inputValue;
        //   const response = await fetch(`http://127.0.0.1:8000/main/search-skill?searchtext=${inputValue}`);
        // Make the GET request with query parameters
        const response = await axios.get(API, {
        headers: {
            'Authorization': token
        },
        params: params  // Add the params to the request
        });  
        if(response.data.data) {
                // Filter out skills that are already in skillsData
      const newSkills = response.data.data.filter(apiSkill => 
        !skillsData.some(existingSkill => existingSkill.skillName === apiSkill.skillName)
      );

      const sortedSkills = newSkills.sort((a, b) => 
      a.displaySkillName.localeCompare(b.displaySkillName)
    );            setSkillSuggestions(sortedSkills);

        }
        } catch (error) {
          console.error('Error fetching skill data:', error);
        }
      }
    };

    fetchSkills();
  }, [inputValue]);

  const handleSkillNameChange = (_, newValue) => {
    const selectedSkill = skillSuggestions.find(skill => skill.displaySkillName === newValue);
    if (selectedSkill) {
      setNewSkill({ ...newSkill, skillName: selectedSkill.skillName });
    } else {
      setNewSkill({ ...newSkill, skillName: newValue });
    }
  };
  
  const handleInputChange = (event, newInputValue) => {
      setInputValue(newInputValue);
      setNewSkill({ ...newSkill, skillName: newInputValue });
  };

  const handleMasteryChange = (event) => {
    setNewSkill({ ...newSkill, onetSkillMastery: event.target.value });
  };

  const handleCriticalityChange = (event) => {
    setNewSkill({ ...newSkill, criticality: event.target.value });
  };

  const handleProfessionChange = (event) => {
    setNewSkill({ ...newSkill, profession: event.target.value });
  };

  const handleExperienceChange = (event) => {
    setNewSkill({ ...newSkill, requiredExperience: event.target.value });
  };

  // Save the new skill and close the dialog
  const handleSaveAndClose = () => {
    handleSaveAndNew(); // First, perform the save and new action
    onClose(); // Then close the dialog
  };
    // Save the new skill and reset the fields for new entry
    const handleSaveAndNew = () => {
      onSave(newSkill);
      setNewSkill({ skillName: '', onetSkillMastery: 0, criticality: 'Low', profession: 'General', requiredExperience: 0 });
      setInputValue('');
    };
 // Extract unique professions from skillsData and sort them
 let uniqueProfessions = [...new Set(skillsData.map(skill => skill.profession))];
 // Add 'General' if it's not already in the array
 if (!uniqueProfessions.includes('General')) {
   uniqueProfessions = ['General', ...uniqueProfessions];
 }
 
 // Sort the array alphabetically, taking care to keep 'General' as the first option
 uniqueProfessions = uniqueProfessions.sort((a, b) => {
   if (a === 'General') return -1;
   if (b === 'General') return 1;
   return a.localeCompare(b);
 });
 



  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add New Skill</DialogTitle>
      <DialogContent style={{ height: '400px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <Box flexBasis="65%">
          <Autocomplete
            freeSolo
            value={newSkill.skillName}
            onChange={handleSkillNameChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            options={skillSuggestions.map(suggestion => suggestion.displaySkillName)}
            renderInput={(params) => <TextField InputLabelProps={{
              style: { lineHeight: '1.5', top: '-6px' },
            }} style={{ margin: '8px 0' }} {...params} label="Skill Name" variant="outlined" fullWidth />}
          />
          </Box>
          <Box flexBasis="15%">
                      <TextField
                        label="Mastery Level"
                        type="number"
                        inputProps={{ min: "1", max: "10", step: "0.5" }}
                        value={newSkill.onetSkillMastery}
                        onChange={handleMasteryChange}
                        fullWidth
                      />
                    </Box>
          <Box flexBasis="15%">
            <TextField
              label="Criticality"
              select
              SelectProps={{ native: true }}
              value={newSkill.criticality}
              onChange={handleCriticalityChange}
              fullWidth
            >
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </TextField>
          </Box>
          </Box>
          <Box display="flex" justifyContent="left" alignItems="center" flexWrap="wrap">

          <Box flexBasis="45%">
            <TextField
              label="Profession"
              select
              SelectProps={{ native: true }}
              value={newSkill.profession}
              onChange={handleProfessionChange}
              fullWidth
            >
              {uniqueProfessions.map(profession => (
                <option key={profession} value={profession}>{profession}</option>
              ))}
            </TextField>
          </Box>
          &nbsp;&nbsp;
          <Box flexBasis="26%">
            <TextField
              label="Experience"
              type="number"
              inputProps={{ min: "0", max: "20", step: "1" }}
              value={newSkill.requiredExperience}
              onChange={handleExperienceChange}
              fullWidth
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSaveAndClose}>Save</Button>
        <Button onClick={handleSaveAndNew}>Save and New</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSkillDialog;
