import { createAsyncThunk } from "@reduxjs/toolkit";

import * as service from "./resume_service"; // Changed to camelCase
import { Settings } from "@mui/icons-material";
import { last } from "pdf-lib";
const millisecondsInAYear = 1000 * 60 * 60 * 24 * 365.25; // Account for leap years

function isMissingYear(value) { 
  const yearRegex = /(?:\D|^)(\d{2}|\d{4})(?:\D|$)/;
  if ( typeof value === 'string') {
    return ( value.toLowerCase().trim() === 'not specified' || 
        !yearRegex.test(value) 
      );
  }
  let strValue = value.toString();
  return !yearRegex.test(strValue);
  }


export const getResumesThunk = createAsyncThunk(
    'resume/getResumes', // Changed to camelCase
    async (jobId, thunkAPI) => { // Changed to camelCase
      const resumes = await service.getResumes(jobId); // Changed to camelCase
    // Calculate displayTitle for each resume
    const settings = thunkAPI.getState().settings.basic;
    const HOPS_THRESHOLD = settings?.HOPS_THRESHOLD || 2;

    const updatedResumes = resumes.map( (row, index) => {
//      console.log(`Processing row ${index}:`, row);
      let workExperience = row.resumeData?.extracted?.workExperience || [];
      
      // Initialize an empty object for the industry experience hash
      let industryExperienceHash = {};
      let numberOfJobs = 0;
      let numberOfJobsMissingStartDate = 0;
      let avgTenure = 0.0;
      let tenureDenom = 0;
      let numHops = 0; 
      let companySummary = [];
      let overallTenure = 0.0;
      let overallRoles = 0;
      let currentCompany = null;
      let lastWorkingDate = new Date(0);

      // Iterate through workExperience and accumulate years of experience by industry
      if (typeof workExperience === 'object' && workExperience !== null && !Array.isArray(workExperience)) {
        workExperience = [ workExperience ];
      }

      const validateStartDateYears = (data) => {
        let lastCompany = null;
        let lastRoleWasValid = false;
        let numberMissingStartDate = 0;
        let thisRoleIsValid = false;
        data.forEach( (item) => {
          const startDate = new Date(item.corrected_startDateOrYear);
          const endDate = new Date(item.corrected_endDateOrYear);
          if (!(isNaN(startDate) || isNaN(endDate)) ) {
            thisRoleIsValid = true;
          }
          else {
            thisRoleIsValid = false;
          }
          if (!thisRoleIsValid) {
            // Was last role with same company, and that was valid?
            if ((lastCompany === item.companyName) && lastRoleWasValid) {
              thisRoleIsValid = true;
            }
            else { 
              numberMissingStartDate += 1;
            }
          }
          lastCompany = item.companyName;
          lastRoleWasValid = thisRoleIsValid;
        });
        return numberMissingStartDate;
      };
      
     const missingDateCount = workExperience.length > 0 ? validateStartDateYears(workExperience) : 1;

     const filteredData = workExperience.filter(item => {
        const startDate = new Date(item.corrected_startDateOrYear);
        const endDate = new Date(item.corrected_endDateOrYear);
        return !(isNaN(startDate) || isNaN(endDate));
      })


      const sortedData = filteredData.sort((a, b) => {
        // First, compare by corrected_startDateOrYear
        const dateA = new Date(a.corrected_startDateOrYear);
        const dateB = new Date(b.corrected_startDateOrYear);
      
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
      
        // If dates are equal, then compare by corrected_years_of_experience
        return a.corrected_years_of_experience - b.corrected_years_of_experience;
      });


      sortedData.forEach(item => {
//        console.log("Processing workexperience:", item);
        overallRoles += 1;

        // Setup industry statistics

        let industry = 'unknown';
        if (item.correct_endDateOrYear > lastWorkingDate) {
          lastWorkingDate = item.correct_endDateOrYear;
        }
        if ('companyIndustry' in item) {
          industry = item.companyIndustry.toLowerCase();
        }
        if (!industryExperienceHash[industry]) {
          industryExperienceHash[industry] = 0;
        }
        industryExperienceHash[industry] += item.corrected_years_of_experience || 0;

        // Set up company statistics
        if (currentCompany && currentCompany.companyName === item.companyName) {
            // Update existing entry if company name matches
            currentCompany.overlapping_years_of_experience += item.corrected_years_of_experience;
            if (currentCompany.startDateOrYear > item.corrected_startDateOrYear) {
              currentCompany.startDateOrYear = item.correct_startDateOrYear;
            }
            if (currentCompany.endDateOrYear < item.corrected_endDateOrYear) {
              currentCompany.endDateOrYear = item.corrected_endDateOrYear;
            }
            currentCompany.number_of_roles += 1;
        } else {
            // Push previous entry to summary and start a new one
            if (currentCompany) {
                currentCompany.sequential_years_of_experience = ((new Date(currentCompany.endDateOrYear) - new Date(currentCompany.startDateOrYear))/millisecondsInAYear);
                overallTenure += currentCompany.sequential_years_of_experience;
                if (currentCompany.sequential_years_of_experience * 12 < HOPS_THRESHOLD) {
                  currentCompany.hop = 1;
                  numHops += 1;
                }
                companySummary.push(currentCompany);
            }
            tenureDenom += 1;  // number of distinct company stints
            currentCompany = {
                companyName: item.companyName,
                startDateOrYear: item.corrected_startDateOrYear,
                endDateOrYear: item.corrected_endDateOrYear,
                overlapping_years_of_experience: item.corrected_years_of_experience,
                number_of_roles: 1
            };
        }
      });
    
      // Push the last entry
      if (currentCompany) {
        currentCompany.sequential_years_of_experience = ((new Date(currentCompany.endDateOrYear) - new Date(currentCompany.startDateOrYear))/millisecondsInAYear);
        overallTenure += currentCompany.sequential_years_of_experience;
        if (currentCompany.sequential_years_of_experience * 12 < HOPS_THRESHOLD) {
          currentCompany.hop = 1;
          numHops += 1;
        }
        companySummary.push(currentCompany);
      }
    
        //        console.log("computed numberOfJobs:", numberOfJobs, "numberOJobsMissingStartDate:", numberOfJobsMissingStartDate, "avgTenure:", avgTenure, "tenureDenom:", tenureDenom);

      
      if (tenureDenom > 1) {
        avgTenure = overallTenure / tenureDenom;
      }
      
      const updatedRow = {
      ...row,
      displayTitle:
        row.resumeData?.extracted?.workExperience?.[0]?.role ||
        row.resumeData?.extracted?.workExperience?.role ||
        row.resumeData?.components?.atsCandidateInfo?.title,
      lowerDisplayTitle: 
        (row.resumeData?.extracted?.workExperience?.[0]?.role ||
        row.resumeData?.extracted?.workExperience?.role ||
        row.resumeData?.components?.atsCandidateInfo?.title)?.toLowerCase(),
      jobs: numberOfJobs,
      companySummary: companySummary,
      industryExperienceHash: industryExperienceHash,
      tenure: avgTenure,
      career: overallTenure,
      hops: numHops,
      industryExperienceHash:industryExperienceHash,
      numberOfJobsMissingStartDate: missingDateCount,
      lastWorkingDate: lastWorkingDate.toISOString(),
      };
      console.log(`Updated row ${index}:`, updatedRow);
      return updatedRow;
    });      
      return updatedResumes;
    }
);

export const getCandidatesThunk = createAsyncThunk(
    'resume/getCandidates', // Changed to camelCase
    async (path) => {
      const candidates = await service.getCandidates(path); // Changed to camelCase
      return candidates;
    }
    
);
export const getCandidateonScreenThunk = createAsyncThunk(
'resume/getcandidateonscreen',
async (path)=>{
  if (path) {
 //   console.log("Got here in resume_thunk for path", path)
    const candidates = await service.getCandidateonScreen(path); // Changed to camelCase
    return candidates;
  }
  else {
    return null;
  }
}

)

export const updatedecisionThunk = createAsyncThunk(
  'resume/Decision',
  async({jobId,resumeId,decision,userName,reason,reasonId, templateName, templateId, schedule, targetStage=null, unrejectFirst=false}) => {
    console.log("log from update ", jobId,resumeId,decision,reason,templateName,schedule, targetStage, unrejectFirst)
    const j = await service.updatedecision(jobId,resumeId,decision,userName,reason,reasonId,templateName, templateId, schedule, targetStage, unrejectFirst);
    return j;
  }
); 

export const bulkUpdatedecisionThunk = createAsyncThunk(
  'resume/Decision',
  async({jobId,resumeIds,decision,userName,reason,reasonId, templateName, templateId, schedule, bulkAction}) => {
    console.log("log from update ", jobId,resumeIds,decision,reason,templateName,schedule)
    const j = await service.bulkUpdatedecision(jobId,resumeIds,decision,userName,reason,reasonId,templateName, templateId, schedule, bulkAction);
    return j;
  }
); 