import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from "react-redux";

import ReactSlider from 'react-slider';
import {
    Typography,
  } from "@mui/material";

import './HopSlider.css';

const HopsSlider = ({ initialSliderValue, saveSliderValue, sliderMax, HOPS_THRESHOLD }) => {
  const [sliderValue, setSliderValue] = useState(initialSliderValue);
  const [containerWidth, setContainerWidth] = useState('90%');
  const sliderRef = useRef(null);
  const min = 0;
  const handleSliderChange = (newSliderValue) => {
    setSliderValue(newSliderValue);
    saveSliderValue(newSliderValue);
  };


  return (
    <div>
      <div className="slider-container">
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="thumb"
          trackClassName="track"
          ref={sliderRef}
          min={0}
          max={sliderMax}
          value={sliderValue}
          onChange={handleSliderChange}
          style={{ color: 'white'}}
          renderThumb={(props, state) => (
            <div {...props}
            style={{...props.style, 
            backgroundColor: "#1976d2"}}>
            {state.valueNow}
           </div>
            
          )}
        />
              <Typography
        variant="body2"
        sx={{
          position: 'absolute',
          top: '-6px',
          left: '-23%',
          transform: 'translateX(-25%)',
          color: '#000',
        }}
      >
        Hops<br /><span style={{ fontSize: "0.75em" }}>({HOPS_THRESHOLD} months)</span>
      </Typography>
      </div>
    </div>
  );
};

export default HopsSlider;
